.chapters-page {
  .cover-background {
    background-repeat: no-repeat !important;
    height: 270px;
    background-size: cover !important;
    background-position: center center !important;
    position: relative !important;

    .container-img {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: flex-start;
      flex-direction: column;
      color: #4c4c4c !important;

      .img-card {
        border-radius: 10px;
        max-height: 150px;
        box-shadow: 12px 29px 81px 0 rgb(0 0 0 / 100%);
      }
    }
  }

  .container-infos {
    margin-top: -22%;

    .row {
      width: 100%;
      justify-content: center;
      flex-direction: inherit;
      align-content: flex-start;
      align-items: center;
    }

    .container-title {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  .mt-60 {
    margin-top: 60px;
  }

  .media-container {
    text-align: center;

    img {
      max-height: 250px;
    }
  }

  .description {
    margin-top: 20px;
  }

  .mb-15 {
    margin-bottom: 15px;
  }

  .button-library {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .chapter {
    padding: 5px 10px;

    ion-label {
      word-break: break-all;
      white-space: normal;
    }
  }

  .progress-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
  }

  .container-chapters .span-chapters:last-child .chapter {
    --inner-border-width: 0;
  }

  @media screen and (max-width: 768px) {
    .button-library {
      position: relative;
      transform: initial;
      left: initial;
      text-align: center;
      margin-top: 20px;
    }
  }

  .download-bar {
    position: fixed;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }

  .checkbox {
    margin-right: 15px;
  }

  .rotate {
    transform: rotate(180deg);
  }
}

.dark .chapters-page {
  .cover-background {
    .container-img {
      color: #fff !important;
    }
  }
}
