.edit-ftp-access-modal {
  .add-icon {
    font-size: 40px;
    margin-top: 12px;
    cursor: pointer;
  }

  .cancel-button {
    width: 100%;
  }
}
