.scan-list-page {
  .loading {
    margin-top: 15px;
  }

  .search-button {
    width: auto;
  }

  .container-badge {
    margin: 10px;
  }

  ion-fab-button {
    --background: var(--ion-color-step-250) !important;
  }

  ion-fab {
    margin-right: 8px;
  }

  .no-result {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 80%;
    padding: 0 20px;
    text-align: center;
  }
}
