.welcome-page {
  .avatar {
    margin: 30px 0;
    text-align: center;
  }

  .avatar ion-avatar {
    display: inline-block;
    width: 200px;
    height: 200px;
  }

  .api-url {
    position: absolute;
    bottom: 62px;
    right: 6px;
  }

  @media screen and (max-height: 357px) {
    .container-buttons-wallpaper {
      height: auto !important;
    }
  }

  .container-buttons-wallpaper {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    height: 100%;
  }

  @media screen and (min-width: 450px) {
    .content-wallpaper {
      width: 400px;
    }

    .container-buttons-wallpaper {
      align-items: center;
    }
  }
}
