.reader {
  .bg-reader {
    --background: #3a3a3a !important;
  }

  .slide-image {
    margin-top: -5px;
  }

  .react-transform-wrapper {
    text-align: center !important;
    display: inline-block !important;
  }

  .container-img {
    text-align: center !important;
    margin-top: -5px !important;
    display: inline-block !important;
    width: 100%;
  }

  .react-transform-component {
    width: 100% !important;
  }

  ion-slides {
    height: 100%;
  }

  .ion-slides {
    .slide-image {
      height: 100% !important;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
    }

    .slide-image::before {
      content: " ";
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }

    .slide-image img {
      vertical-align: middle;
      display: inline-block;
    }
  }
}
