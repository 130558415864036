.download-manga-page {
  .read {
    color: #707070;
  }

  ion-label {
    word-break: break-all;
    white-space: normal;
  }

  .checkbox {
    margin-right: 15px;
  }

  .container-chapters .span-chapters:last-child .chapter {
    --inner-border-width: 0;
  }

  .progress-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
  }

  .delete-bar {
    position: fixed;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }

  .avatar {
    margin: 30px 0;
    text-align: center;
  }

  .avatar ion-avatar {
    display: inline-block;
    width: 200px;
    height: 200px;
  }
}
