.grid-cover {
  ion-item {
    --padding-end: 0;
  }

  .img {
    width: 100%;
    border-radius: 10px;
  }

  .badge-cover {
    position: absolute;
    z-index: 2;
    background-color: #2a2a2a;
    color: white;
    font-size: 16px;
    padding: 12px;
    right: 0;
    border-radius: 0 0 0 10px;
  }

  .badge-read {
    position: absolute;
    z-index: 2;
    background-color: var(--ion-color-primary);
    color: white;
    font-size: 16px;
    padding: 12px;
    left: 0;
    border-radius: 0 0 10px;
  }

  .manga-card .routing-ion-item {
    --padding-start: 0;
    --inner-padding-end: 0;
  }

  .card-content {
    padding: 0;
    text-align: center;
    width: 100%;
  }

  h3 {
    margin-top: 0;
    margin-left: 10px;
  }

  .badge-text {
    vertical-align: top;
  }

  .badge-icon {
    vertical-align: bottom;
  }

  .container-badge .badge {
    margin-left: 10px;
  }

  .container-badge:first-child {
    margin-left: 0;
  }

  .in-favorite {
    position: absolute;
    display: flex;
    color: white;
    font-weight: bold;
    z-index: 2;
    flex-direction: column;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: space-around;
    font-size: 16px;

    .text {
      padding: 10px;
      background-color: rgb(0 0 0 / 50%);
    }
  }

  .in-favorite-2 {
    color: red;
    font-weight: bold;
  }
}
