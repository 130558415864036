.favorites-page {
  .avatar {
    margin: 30px 0;
    text-align: center;
  }

  .badge-notifications {
    position: absolute;
    top: -6px;
    right: 2px;
    border-radius: 15px;
    padding: 5px;
    min-width: 20px;
    font-size: 11px;
  }

  .relative {
    position: relative;
  }

  .avatar ion-avatar {
    display: inline-block;
    width: 200px;
    height: 200px;
  }

  .margin-button {
    margin-top: 40px;
  }

  .bottom-bar {
    position: fixed;
    bottom: 0;
    z-index: 1;
    width: 100%;
    border-top: 1px solid;
  }

  .divider {
    --padding-start: 0;
  }

  .tab-bar-button {
    --padding-end: 5px;
    --padding-start: 5px;

    max-width: none;
    font-size: 16px;
    color: white;
    height: 50px;
  }

  .tab-selected {
    color: var(--ion-color-primary) !important;
    border-bottom: 1px solid var(--ion-color-primary) !important;
  }
}
