.account-page {
  .avatar {
    margin: 30px 0;
    text-align: center;
  }

  .avatar ion-avatar {
    display: inline-block;
    width: 200px;
    height: 200px;
  }

  .action-list ion-item:last-child {
    --inner-border-width: 0;
  }

  @media screen and (max-height: 843px) {
    .container-buttons-wallpaper {
      height: auto !important;
    }
  }
}
