.manga-ftp-page {
  ion-item {
    --padding-end: 0;
  }

  .img {
    width: 100%;
    border-radius: 10px;
  }

  .manga-card {
    ion-item {
      padding: 0;
    }
  }

  .badge-cover {
    position: absolute;
    z-index: 2;
    background-color: #2a2a2a;
    color: white;
    font-size: 16px;
    padding: 12px;
    right: 0;
    border-radius: 0 0 0 10px;
  }

  .badge-read {
    position: absolute;
    z-index: 2;
    background-color: var(--ion-color-primary);
    color: white;
    font-size: 16px;
    padding: 12px;
    left: 0;
    border-radius: 0 0 10px;
  }

  .manga-card .routing-ion-item {
    --padding-start: 0;
    --inner-padding-end: 0;
  }

  .card-content {
    padding: 0;
    text-align: center;
    width: 100%;
  }

  .add-icon {
    font-size: 40px;
    margin-top: 12px;
    cursor: pointer;
  }

  .cancel-button {
    width: 100%;
  }
}
