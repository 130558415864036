/* Optional CSS, this is added for the flashing that happens when toggling between themes */
ion-item {
  --transition: none;
  --padding-end: 16px;
  --inner-border-width: 0;
  --border-width: 0 !important;

  cursor: pointer;
}

ion-card,
ion-item {
  --background: #e9e7e7;
}

.dark ion-card,
.dark ion-item {
  --background: var(--ion-color-step-100);
}

.ion-item-with-padding {
  ion-item {
    padding: 10px;
  }
}

.ion-item-no-padding-start {
  --padding-start: 0;
}

.ion-item-no-padding-end {
  --padding-end: 0 !important;
}

.mr-15 {
  margin-right: 15px;
}

ion-toolbar {
  border-bottom: 1px solid var(--ion-color-primary);

  ion-title {
    padding: 10px;
  }
}

.dark ion-toolbar {
  border-bottom: 1px solid #666f;
}

.dark .ion-page {
  background-color: var(--ion-color-step-100);
}

.alert-wrapper,
.loading-wrapper {
  border: 1px solid #dbdbdb;
}

.action-sheet-button {
  border-top: 1px solid #dbdbdb !important;
}

.action-sheet-container:last-child {
  border-bottom: 1px solid #dbdbdb;
}

.badge {
  padding: 6px;
  margin: 8px;
  font-size: 17px;
  cursor: pointer;

  --background: var(--ion-color-primary);
}

.dark .MuiButtonBase-root {
  color: white !important;
}

.dark .Mui-selected {
  color: var(--ion-color-primary) !important;
}

.dark .badge {
  padding: 6px;
  margin: 8px;
  font-size: 17px;
  cursor: pointer;

  --background: #646464 !important;
}

.badge-selected {
  --background: #88c3ff;
}

.dark .badge-selected {
  --background: #a5a5a5ff !important;
}

ion-buttons {
  ion-button {
    --background: #e9e7e7 !important;
    --border-radius: 5px !important;

    margin-right: 10px !important;
  }
}

.dark ion-buttons {
  ion-button {
    --background: #3e3e3e !important;
    --border-radius: 5px !important;

    margin-right: 10px !important;
  }
}

.read {
  color: #59a5f3ff;
}

.dark .read {
  color: #707070;
}

.badge-cover {
  position: absolute;
  z-index: 2;
  background-color: #2a2a2a;
  color: white;
  font-size: 16px;
  padding: 12px;
  right: 0;
  border-radius: 0 0 0 10px;
}

.card-content-md {
  line-height: 1;
}

.description-container {
  position: absolute;
  bottom: 0;
  background: #424242;
  width: 100%;
  opacity: 0.8;
  color: white;
  padding: 10px;
  font-weight: bold !important;
  font-size: 13px !important;
}

.dark .selected {
  --background: var(--ion-color-primary) !important;
}

.tab-bar-button-footer {
  --padding-end: 5px;
  --padding-start: 5px;

  max-width: none;
  font-size: 14px;
}

.tab-selected {
  border-top: 1px solid var(--ion-color-primary) !important;
}

ion-tab-bar {
  justify-content: space-between;
}

.register-button {
  --color: black;
  --border-color: var(--ion-color-primary, #3880ff);
}

.dark .register-button {
  --color: white !important;
}

.tab-bar-button {
  color: black !important;

  &.tab-selected {
    color: var(--ion-color-primary) !important;
  }
}

.dark .tab-bar-button {
  color: #fff !important;

  &.tab-selected {
    color: var(--ion-color-primary) !important;
  }
}

.alert-button-group {
  justify-content: center !important;
  padding-top: 0 !important;
}

.alert-button-read {
  border: 1px solid var(--ion-color-primary) !important;
  color: var(--ion-color-primary) !important;
  border-radius: 5px !important;
  font-weight: 900 !important;
  text-align: center !important;
  min-width: 110px !important;
  margin-inline-end: 0 !important;

  .alert-button-inner {
    justify-content: center !important;
  }
}

.alert-button-fiche {
  border: 1px solid var(--ion-color-tertiary) !important;
  color: var(--ion-color-tertiary) !important;
  border-radius: 5px !important;
  font-weight: 900 !important;
  text-align: center !important;
  min-width: 110px !important;

  .alert-button-inner {
    justify-content: center !important;
  }
}

.alert-button-cancel {
  border: 1px solid var(--ion-color-danger) !important;
  color: var(--ion-color-danger) !important;
  border-radius: 5px !important;
  font-weight: 900 !important;
  text-align: center !important;
  min-width: 110px !important;

  .alert-button-inner {
    justify-content: center !important;
  }
}

.alert-button-validation {
  border: 1px solid var(--ion-color-primary) !important;
  color: var(--ion-color-primary) !important;
  border-radius: 5px !important;
  font-weight: 900 !important;
  text-align: center !important;
  min-width: 110px !important;
  margin-inline-end: 0 !important;

  .alert-button-inner {
    justify-content: center !important;
  }
}

.alert-title {
  text-align: center !important;
  font-weight: bold !important;
  padding-bottom: 9px !important;
  border-bottom: 1px solid #dbdbdb !important;
  font-size: 19px !important;
}

.dark .alert-button-fiche {
  border: 1px solid var(--ion-color-step-100) !important;
  color: var(--ion-color-primary) !important;
  border-radius: 5px !important;
  font-weight: 900 !important;
  text-align: center !important;
  min-width: 110px !important;

  .alert-button-inner {
    justify-content: center !important;
  }
}

.ion-item-title {
  --background: var(--ion-color-primary) !important;

  ion-title {
    padding-inline: 0;
  }
}

.cover-mini {
  max-height: 150px !important;
  padding-right: 15px;
}

.p10 {
  padding: 10px;
}

.w-100 {
  width: 100% !important;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-20 {
  margin-bottom: 20px;
}

.margin-button {
  padding: 10px;
}

.wallpaper {
  --background: none;

  background-size: cover;
}

.content-wallpaper {
  margin: 0 !important;
  background: rgb(197 197 197 / 70%);
  border-radius: 10px;
  width: 100%;
}

.dark .content-wallpaper {
  margin: 10px;
  background: rgb(18 18 18 / 70%);
  border-radius: 10px;
}

.container-buttons-wallpaper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  align-items: center;
}

.mt-20 {
  margin-top: 20px;
}

.update-in-progress {
  border-top: 1px solid #666;
  text-align: center;
  color: black;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
  padding: 0;
}

.dark .update-in-progress {
  border-top: 1px solid #666;
  color: white;
}

.download-bar {
  border-top: 1px solid var(--ion-color-primary);
}

.dark .download-bar {
  border-top: 1px solid #666f;
}

.alert-head.sc-ion-alert-md {
  text-align: center;
}
