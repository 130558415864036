.source-page {
  .border {
    margin-left: 15px;
  }

  .img-search {
    vertical-align: middle;
  }

  .text-search {
    vertical-align: middle;
    display: inline-block;
    margin-left: 10px;
  }

  ion-fab-button {
    --background: var(--ion-color-step-250) !important;
  }

  ion-fab {
    margin-right: 8px;
  }

  ion-avatar {
    display: inline-block;
    width: 30px;
    height: 30px;
    vertical-align: middle;
  }

  .container-badge {
    margin-left: 10px;
  }

  .divider {
    --padding-start: 0;
  }

  .tab-bar-button {
    --padding-end: 5px;
    --padding-start: 5px;

    max-width: none;
    font-size: 16px;
    color: white;
    height: 50px;
  }

  .tab-selected {
    color: var(--ion-color-primary) !important;
    border-bottom: 1px solid var(--ion-color-primary) !important;
  }

  .content-wallpaper {
    padding-bottom: 80px;
  }
}
