.tags-modal {
  .badge {
    padding: 6px;
    margin: 8px;
    font-size: 17px;
    cursor: pointer;

    --background: #646464;
  }
}
